import styled from "styled-components";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
// import 'react-accessible-accordion/dist/fancy-example.css';

import './checkbox_list_style.css';

import { CheckBoxListItem } from "./CheckBoxListItem";
import { ArticleDir } from "../../../../../models/articleDir";

const CheckBoxListContainer = styled.div`
font-size: 14px;
margin-top: 0px;
`;

const AccordionContainer = styled.div`
display:flex;
`;

const AccordionChildContainer = styled.div`
margin-left: 20px;
margin-bottom: 10px;
`;

type Props = {
  dir?: ArticleDir
  refleshDirList: (dir:ArticleDir) => void,
  dirKey: string
}

export const CheckBoxList = (props: Props) => {
  const dir = props.dir;

  return (
    <CheckBoxListContainer>
      {dir?.children == null ? (
        <CheckBoxListItem dir={dir} refleshDirList={props.refleshDirList} dirKey={props.dirKey} />
      ) : (
        <AccordionContainer>
          <Accordion allowZeroExpanded>
            <AccordionItem dangerouslySetExpanded={dir.isExpand}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <CheckBoxListItem dir={dir} refleshDirList={props.refleshDirList} dirKey={props.dirKey} />
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <AccordionChildContainer>
                  {dir.children.map((child) => {
                    return (
                      <CheckBoxList key={child.name}  dir={child} refleshDirList={props.refleshDirList} dirKey={props.dirKey} />
                    )
                  })}
                </AccordionChildContainer>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </AccordionContainer>
      )}
    </CheckBoxListContainer>
  );
};