import styled from "styled-components";

import { CheckBoxList } from "./CheckBoxList";
import { ArticleDir } from "../../../../../models/articleDir";
import { SpecialArticleDir } from "../../../../../models/specialArticleDir";
import React from "react";
import { CheckBoxListItem } from "./CheckBoxListItem";

const CheckBoxLMenuContainer = styled.div`
`;

const CheckBoxTitleH1 = styled.h1<{imageUrl: string}>`
font-size: 20px;
font-weight: bold;
margin: 20px 10px 0px 20px;
&:before{
    content: "";
    display: inline-block;
    width: 22px;
    height: 22px;
    background-image: url(${(props) => props.imageUrl});
    background-repeat: no-repeat;
    vertical-align: -0.2em;
    margin-right: 8px;
}
`;

const CheckBoxListContainer = styled.div`
margin: 15px 5px 15px 15px;
`;

type Props = {
    existAll: boolean,
    dir?: ArticleDir,
    refleshDirList: (dir:ArticleDir) => void,
    title: string,
    dirKey: string,
    imageUrl: string,
    count?: number
}

export const CheckBoxMenu = (props: Props) => {

    return (
        <CheckBoxLMenuContainer>
            <CheckBoxTitleH1 imageUrl={props.imageUrl}>{props.title}</CheckBoxTitleH1>

            <CheckBoxListContainer>
                {
                    props.existAll && (
                        <CheckBoxListItem dir={props.dir} refleshDirList={props.refleshDirList} dirKey={props.dirKey}/>
                    )
                }

                {props.dir?.children?.map((child) => {
                    return (
                        <CheckBoxList dir={child} key={child.name} refleshDirList={props.refleshDirList} dirKey={props.dirKey}/>
                    )
                })}
            </CheckBoxListContainer>
        </CheckBoxLMenuContainer>
    );
};