import styled from "styled-components";

const FooterContainer = styled.div`
height: 70px;
background-color: #6A737C;
display: flex;
align-items: center;
justify-content: center;
`;

const H3Tilte = styled.h3`
font-size: 15px;
color: gainsboro;
`;

export const Footer = () => {
    return (
        <FooterContainer>
            <H3Tilte>&copy; data science wiki</H3Tilte>
        </FooterContainer>
    );
};