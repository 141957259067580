import styled from "styled-components";
import { Link } from 'react-router-dom';

const GPTsMenuContainer = styled.div`
margin-bottom:20px;
`;

const GPTsMenuTitleH1 = styled.h1`
font-size: 17px;
font-weight: bold;
&:before{
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url("/openai_icon.svg");
    background-repeat: no-repeat;
    vertical-align: -0.2em;
    margin-right: 8px;
}
`;

const GptsContainer = styled.div`
margin-top: 10px;
margin-left:15px;
`

const GPTsLink = styled(Link)`
margin-left: 20px;
text-aline: center;
font-size: 14px;
height: 30px;
line-height: 30px;


&[target=_blank] {
    padding-top: 3px;
    padding-right: 18px;
	background: url(/external_link_icon.svg) right center/11px auto no-repeat;
}
`

export const GPTsMenu = () => {

    return (
        <GPTsMenuContainer>
            <GPTsMenuTitleH1>GPTs</GPTsMenuTitleH1>

            <GptsContainer>
                <GPTsLink to="https://chat.openai.com/g/g-w0APV35yz-data-science-wiki-gpts" target="_blank">Data Science Wiki GPTs</GPTsLink>
            </GptsContainer>
        </GPTsMenuContainer>
    );
};