import styled from "styled-components";
import { Link } from 'react-router-dom';
import { ArticleInfo } from "../../../../../models/articleInfo";

const ArticleListItemContainer = styled.div`
position: relative;
min-width: 180px;
    margin-top: 20px;
    border-radius: 10px;
    padding: 15px 20px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    &:hover{
        background-color: rgb(245, 245, 245);
    }
`;

const CustomLink = styled(Link)`
    text-decoration: none;
`;

const ArticleHeaderContainer = styled.div`
display:flex;
`

const ArticleTitle = styled.h2`
    font-size: 20px;
    font-weight: bold;
    color: #555;
    overflow-wrap: anywhere;
    line-height: 1.2em;
    white-space: pre-wrap;
`

const ArticleDatetime = styled.p`
height:10px;
    top: 10px;
    font-size: 13px;
    color: #999;
    margin-left: auto;
`

const ArticleCategory = styled.p`
    font-size: 14px;
    margin-top: 10px;
    font-weight: bold;
    color: #555;
    overflow-wrap: anywhere;
    line-height: 1.2em;
`

const ArticleOverview = styled.div`
    font-size: 13px;
    margin:0;
    margin-top: 10px;
    color: #666;
    overflow-wrap: anywhere;
    line-height: 1.2em;
    white-space: pre-wrap;
`

type Props = {
    articleInfo: ArticleInfo
}

export const ArticleListItem = (props: Props) => {

    return (
        <ArticleListItemContainer>
            <CustomLink to={`/article${props.articleInfo.path.replace(".html", "")}`}>
                <ArticleHeaderContainer>
                    <ArticleTitle>{props.articleInfo.title}</ArticleTitle>
                    <ArticleDatetime>{props.articleInfo.created_at.toLocaleDateString()}</ArticleDatetime>
                </ArticleHeaderContainer>
                <ArticleCategory>{props.articleInfo.displayDirPath.join(" > ")}</ArticleCategory>
                <ArticleOverview>{props.articleInfo.overview}</ArticleOverview>
            </CustomLink>
        </ArticleListItemContainer>
    );
};