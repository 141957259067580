import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import { FaSquareXTwitter } from 'react-icons/fa6'

const HeaderContainer = styled.div`
    background-color:#434343ff;
    height: 80px;
    padding-left: 10px;
    box-sizing: border-box;
    display:flex;
`;

const TitleContainer = styled.div`
height:80px;
cursor: pointer;
`

const TitleImage = styled.img`
height:100%;
`

const SnsContainer = styled.div`
margin: auto 18px auto auto;
`

export const HeaderSp = () => {
    const navigate = useNavigate();

    return (
        <HeaderContainer>
            <TitleContainer onClick={()=>navigate("/")}>
                <TitleImage src="/title.png" alt="Data Science Wiki" onClick={()=>navigate("/")}/>
            </TitleContainer>

            <SnsContainer>
                <a href="https://twitter.com/ds_wiki_?ref_src=twsrc%5Etfw">
                    <FaSquareXTwitter color={'#ccc'} size={40} />
                </a>
            </SnsContainer>
        </HeaderContainer>
    );
};