import { useState, useEffect } from "react";

import { getDirMaster, getArticleInfo, getSpecialDirMaster } from "../services/efsAccessor";
import { ArticleDir } from "../models/articleDir";
import { ArticleInfo } from "../models/articleInfo";
import { SpecialArticleDir } from "../models/specialArticleDir";
import { SpecialDirMasterEntity } from "../models/entity/specialDirMasterEntity";
import { DirMasterEntity } from "../models/entity/dirMasterEntity";

export const useAritcleInfo = (fileEndpoint: string) => {
    const [articleList, setArticleList] = useState<ArticleInfo[]>();

    useEffect(() => {
        getArticleInfo(fileEndpoint).then((result) => {
            const infos: ArticleInfo[] = result.map(info => {
                return new ArticleInfo(info);
            });
            setArticleList([...infos]);
        });
    }, []);

    return { articleList, setArticleList };
};

export const useDirMaster = (fileEndpoint: string) => {
    const [articleDir, setArticleDir] = useState<ArticleDir>();

    useEffect(() => {
        getDirMaster(fileEndpoint).then((result) => {
            const rootDir: ArticleDir = new ArticleDir("root", "すべて");

            rootDir.setChildren(result);

            setArticleDir(rootDir);
        });
    }, []);

    return { articleDir, setArticleDir };
};

export const useSpecialDirMaster = (fileEndpoint: string) => {
    const [specialArticleDir, setSpecialArticleDir] = useState<SpecialArticleDir>();

    useEffect(() => {
        getSpecialDirMaster(fileEndpoint).then((result) => {
            const rootDir: SpecialArticleDir = new SpecialArticleDir("root", "すべて");

            rootDir.setChildren(result);

            setSpecialArticleDir(rootDir);
        });
    }, []);

    return { specialArticleDir, setSpecialArticleDir };
};