import styled from "styled-components";
import { Link } from 'react-router-dom';

const GPTsMenuContainer = styled.div`
margin-bottom:20px;
`;

const GPTsMenuTitleH1 = styled.h1`
font-size: 20px;
font-weight: bold;
margin: 20px 10px 0px 20px;
&:before{
    content: "";
    display: inline-block;
    width: 22px;
    height: 22px;
    background-image: url("/openai_icon.svg");
    background-repeat: no-repeat;
    vertical-align: -0.2em;
    margin-right: 8px;
}
`;

const GptsContainer = styled.div`
margin: 15px 5px 15px 15px;
`

const GPTsLink = styled(Link)`
margin-left: 30px;
text-aline: center;
font-size: 18px;
height: 30px;
line-height: 30px;


&[target=_blank] {
    padding-top: 2px;
    padding-right: 23px;
	background: url(/external_link_icon.svg) right center/14px auto no-repeat;
}
`

export const GPTsMenu = () => {

    return (
        <GPTsMenuContainer>
            <GPTsMenuTitleH1>GPTs</GPTsMenuTitleH1>

            <GptsContainer>
                <GPTsLink to="https://chat.openai.com/g/g-w0APV35yz-data-science-wiki-gpts">Data Science Wiki GPTs</GPTsLink>
            </GptsContainer>
        </GPTsMenuContainer>
    );
};