import styled from "styled-components";
import "./checkbox_style.css";
import { ArticleDir } from "../../../../../models/articleDir";

const CheckBoxListItemContainer = styled.div`
width: 100%;
`;

type Props = {
    dir?: ArticleDir
    refleshDirList: (dir: ArticleDir) => void,
    dirKey: string
}

export const CheckBoxListItem = (props: Props) => {

    const dir = props.dir;

    const onChange = () => {
        if (dir) {
            dir.isDisplayChecked = !dir.isDisplayChecked;
            dir.isChecked = dir.isDisplayChecked;
            dir.isExpand = dir.isDisplayChecked;
            props.refleshDirList(dir);
        }
    };

    return (
        <CheckBoxListItemContainer>
            <input type="checkbox" id={props.dirKey + "_" + dir?.name} className="checkbox" onChange={onChange} checked={dir?.isDisplayChecked ? true : false} />
            <label className="checkbox-label" onClick={onChange}>{dir?.display_name}</label>
        </CheckBoxListItemContainer>
    );
};