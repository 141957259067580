import styled from "styled-components";

import { CheckBoxList } from "./CheckBoxList";
import { ArticleDir } from "../../../../../models/articleDir";
import { CheckBoxListItem } from "./CheckBoxListItem";

const CheckBoxLMenuContainer = styled.div`
margin-bottom:25px;
`;

const CheckBoxListContainer = styled.div`
margin-top: 12px;
margin-left:10px;
`;

const CheckBoxTitleH1 = styled.h1<{imageUrl: string}>`
font-size: 17px;
font-weight: bold;
&:before{
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url(${(props) => props.imageUrl});
    background-repeat: no-repeat;
    vertical-align: -0.2em;
    margin-right: 8px;
}
`;

type Props = {
    existAll: boolean,
    dir?: ArticleDir,
    refleshDirList: (dir:ArticleDir) => void,
    title: string,
    dirKey: string,
    imageUrl: string
}

export const CheckBoxMenu = (props: Props) => {

    return (
        <CheckBoxLMenuContainer>
            <CheckBoxTitleH1 imageUrl={props.imageUrl}>{props.title}</CheckBoxTitleH1>

            <CheckBoxListContainer>
                {
                    props.existAll && (
                        <CheckBoxListItem dir={props.dir} refleshDirList={props.refleshDirList} dirKey={props.dirKey}/>
                    )
                }

                {props.dir?.children?.map((child) => {
                    return (
                        <CheckBoxList dir={child} key={child.name} refleshDirList={props.refleshDirList} dirKey={props.dirKey}/>
                    )
                })}
            </CheckBoxListContainer>
        </CheckBoxLMenuContainer>
    );
};