import styled from "styled-components";

import { ArticleList } from "./articlelist/ArticleList";

import { FaSquareXTwitter } from 'react-icons/fa6'
import { ArticleInfo } from "../../../../models/articleInfo";

const MainContainer = styled.div`
flex-grow: 1;
display: flex;
flex-direction: column;
padding: 20px 30px 30px 30px;
`;

/* const InformationContainer = styled.a`
border: 1px solid #cccccc;
border-radius: 10px;
padding: 10px;
font-size: 14px;
vertical-align: center;
display: flex;
margin: 0 auto;

& > p{
    margin: auto 0 auto 10px;
}

&:hover{
    background-color: #ddd;
}
`; */

const ListCountContainer = styled.div`
margin-top: 10px;
color: #555;
display:flex;
`;

const DisplayDirPath = styled.h1<{selectedRootDirImageUrl?: string | null}>`
font-size: 17px;
overflow: hidden;
text-overflow: ellipsis;
&:before{
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url(${(props) => props.selectedRootDirImageUrl});
    background-repeat: no-repeat;
    vertical-align: -0.2em;
    margin-right: 8px;
}
`;

const ListCount = styled.div`
margin-left: auto;
font-size: 15px;
`;

type Props = {
    articleList?: ArticleInfo[],
    selectedDisplayDirPath?: string | null,
    selectedRootDirImageUrl?: string | null
}

export const Main = (props: Props) => {
    return (
        <MainContainer>
            {/*             <InformationContainer href="https://twitter.com/DataScienceWiki?ref_src=twsrc%5Etfw">
                <FaSquareXTwitter color={'#444'} size={40} />
                <p>X（旧Twitter）をフォローして、新しい記事の情報をキャッチしよう！</p>
            </InformationContainer> */}

            <ListCountContainer>
                <DisplayDirPath selectedRootDirImageUrl={props.selectedRootDirImageUrl}>{props.selectedDisplayDirPath}</DisplayDirPath>
                <ListCount>検索結果: {props.articleList?.length}件</ListCount>
            </ListCountContainer>

            <ArticleList articleList={props.articleList} />
        </MainContainer>
    );
};