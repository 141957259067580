import { ArticleInfoEntity } from "./entity/articleInfoEntity";

export class ArticleInfo {
    public title: string;
    public overview: string;
    public path: string;
    public created_at: Date;
    public displayDirPath: string[];
    public dirPath: string;

    constructor(entity: ArticleInfoEntity) {
        this.title = entity.title;
        this.overview = entity.overview;
        this.path = entity.path;
        this.created_at = entity.created_at;
        this.displayDirPath = [];
        this.dirPath = "";
    }
}