import styled from "styled-components";

import { Header } from '../parts/header/Header';
import { HeaderSp } from '../parts/header/HeaderSp';
import { Footer } from '../parts/footer/Footer';
import { useState } from "react";

// import { useSearchParams } from "react-router-dom";

import { useNavigate } from 'react-router-dom';

import { mediaQuery, useMediaQuery } from '../../hooks/mediaQueryProvider'

import {
    useParams
} from 'react-router-dom';
import { ArticleDir } from "../../models/articleDir";
import { ArticleInfo } from "../../models/articleInfo";
import { ArticleList } from "../topPage/parts/main/articlelist/ArticleList";
import { useAritcleInfo } from "../../hooks/useEfsAccessor";

const ArtcileContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #efefefff;
`;

const ContentContainer = styled.div`
height:100%;
min-height: calc(100vh - 180px);
width: 80%;
max-width: 1000px;
margin: 0 auto;
margin-bottom: 30px;
`;

const ContentContainerSp = styled.div`
height:100%;
padding: 0 20px;
margin-bottom: 30px;
`;

const BreadCrumbContainer = styled.div`
display: flex;
flex-wrap: wrap;
width: 100%;
background-color: #efefefff;
font-size: 17px;
padding: 10px 0;
color: #555;
`;

const H1DirTitle = styled.h1`
display: inline-block;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
cursor: pointer;
margin-right: .5em;
height: 30px;
line-height: 30px;
color: #0d75a1;
    &:hover{
        text-decoration: underline;
    }
`;

const H1ArticleTilte = styled.h1`
display: inline-block;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
height: 30px;
line-height: 30px;
`;

const BreadCrumbContainerSp = styled.div`
display: flex;
flex-wrap: wrap;
width: 100%;
background-color: #efefefff;
font-size: 17px;
padding: 10px 0;
color: #555;
`;

const H1DirTitleSp = styled.h1`
display: inline-block;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
cursor: pointer;
margin-right: .5em;
height: 20px;
line-height: 20px;
color: #0d75a1;
&:hover{
    text-decoration: underline;
}
`;

const H1ArticleTilteSp = styled.h1`
display: inline-block;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
height: 20px;
line-height: 20px;
`;

const Iframe = styled.iframe`
width: 100%;
height: 100vh;
border-radius: 10px;
`;

type Props = {
    articleList?: ArticleInfo[],
    selectedDisplayDirPath?: string | null
}

export const Article = (props: Props) => {

    const isSp = useMediaQuery(mediaQuery.sp)

    const navigate = useNavigate();

    // const [searchParams] = useSearchParams();

    const [height, setHeight] = useState<number>();

    const calcHeight = () => {
        const iframe = document.getElementById("iframe");
        if (iframe instanceof HTMLIFrameElement && iframe.contentWindow) {
            setHeight(iframe.contentWindow.document.body.scrollHeight + 170);
        }
    }

    const articleEndpoint = "https://www.data-science-wiki.net/efs/article/";

    const path = useParams()["*"];
    const filename = path + ".html"

    console.log(filename)

    let articleInfoTitle: string | null = null;
    const fileEndpoint: string = process.env.REACT_APP_FILE_ENDPOINT ?? "";
    const { articleList } = useAritcleInfo(fileEndpoint);
    if (articleList) {
        const articleInfo = articleList.filter(info => info.path === "/" + path);
        if (articleInfo[0]) {
            articleInfoTitle = "> " + articleInfo[0].title;
        }
    }

    const displayDirPath = "TOP" + (props.selectedDisplayDirPath ? " > " + props.selectedDisplayDirPath : "");

    return (
        <ArtcileContainer>
            {isSp ? (
                <HeaderSp />
            ) : (
                <Header />
            )}

            {
                isSp ? (
                    <ContentContainerSp>
                        <BreadCrumbContainerSp>
                            <H1DirTitleSp onClick={() => navigate("/")}>{displayDirPath}</H1DirTitleSp>
                            {articleInfoTitle && <H1ArticleTilteSp >{articleInfoTitle}</H1ArticleTilteSp>}
                        </BreadCrumbContainerSp>

                        <Iframe style={{ "height": height }} height={height} src={articleEndpoint + filename} id="iframe" onLoad={calcHeight} allowFullScreen />
                    </ContentContainerSp>

                ) : (
                    <ContentContainer>
                        <BreadCrumbContainer>
                            <H1DirTitle onClick={() => navigate("/")}>{displayDirPath}</H1DirTitle>
                            {articleInfoTitle && <H1ArticleTilte >{articleInfoTitle}</H1ArticleTilte>}
                        </BreadCrumbContainer>

                        <Iframe style={{ "height": height }} height={height} src={articleEndpoint + filename} id="iframe" onLoad={calcHeight} allowFullScreen />
                    </ContentContainer>

                )
            }


            <Footer />
        </ArtcileContainer>
    );
};