import styled from "styled-components";

import { CheckBoxMenu } from "./checkbox/CheckBoxMenu";
import { ArticleDir } from "../../../../models/articleDir";
import { SpecialArticleDir } from "../../../../models/specialArticleDir";
import { ArticleInfo } from "../../../../models/articleInfo";
import { GPTsMenu } from "./GPTsMenu";

const SideBarContainer = styled.div`
min-width: 350px;
box-sizing: border-box;
background-color: #666666ff;
color: #fff;
position: sticky;
top: 80px;
padding: 25px 5px 15px 15px;
overflow-y: auto;
max-height: calc(100vh - 80px);
&::-webkit-scrollbar{
    width: 5px;
 }
&::-webkit-scrollbar-thumb{
    background-color: #888;
    border-radius: 5px;
 }
`;

type Props = {
    articleDir?: ArticleDir,
    specialArticleDir?: SpecialArticleDir,
    refleshDirList: (dir:ArticleDir) => void,
    articleList?: ArticleInfo[]
}

export const SideBar = (props: Props) => {

    return (
        <SideBarContainer>
            <GPTsMenu/>

            <CheckBoxMenu existAll={false} dir={props.specialArticleDir} refleshDirList = {props.refleshDirList} title={"特集記事"} dirKey="special" imageUrl="/special_icon.svg"/>

            <CheckBoxMenu existAll={false} dir={props.articleDir} refleshDirList = {props.refleshDirList} title={"分析手法"} dirKey="analyze" imageUrl="/analyze_icon.svg"/>
        </SideBarContainer>
    );
};