import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

const Global = createGlobalStyle`
 ${reset}
 body {
    font-family: "游ゴシック体", YuGothic, "游ゴシック", "Yu Gothic", sans-serif;
 }
`
const GlobalCSS = () => {
  return <Global />
}

export default GlobalCSS