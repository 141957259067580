import styled from "styled-components";

import { ArticleListItem } from "./ArticleListItem";
import { ArticleListItemSp } from "./ArticleListItemSp";

import {mediaQuery, useMediaQuery} from '../../../../../hooks/mediaQueryProvider'
import { ArticleInfo } from "../../../../../models/articleInfo";

const ArticleListContainer = styled.div`
position:relative;
`;

type Props = {
    articleList?: ArticleInfo[]
}

export const ArticleList = (props:Props) => {

    const isSp = useMediaQuery(mediaQuery.sp)

    return (
        <ArticleListContainer>
            {props.articleList?.map((info)=>{
                if(isSp){
                    return (
                        <ArticleListItemSp articleInfo={info} key={info.path}/>
                    )
                }
                else{
                    return (
                        <ArticleListItem articleInfo={info} key={info.path}/>
                    )
                }
            })}
        </ArticleListContainer>
    );
};