import { Top } from './topPage/Top';
import { Article } from './articlePage/Article';

import { Routes, Route } from 'react-router-dom';

import GlobalCss from '../grobalCss'

import { useDirMaster, useAritcleInfo, useSpecialDirMaster } from '../hooks/useEfsAccessor';
import { useState } from 'react';
import { ArticleInfo } from '../models/articleInfo';
import { ArticleDir } from '../models/articleDir';
import { SpecialArticleDir } from '../models/specialArticleDir';

let originalArticleInfo: ArticleInfo[];

function App() {
  // ファイルのエンドポイント
  const fileEndpoint: string = process.env.REACT_APP_FILE_ENDPOINT ?? "";

  // データ取得
  const { articleDir, setArticleDir } = useDirMaster(fileEndpoint);
  const { articleList, setArticleList } = useAritcleInfo(fileEndpoint);
  const { specialArticleDir, setSpecialArticleDir } = useSpecialDirMaster(fileEndpoint);

  // 表示用Dir
  const [selectedDisplayDirPath, setSelectedDisplayDirPath] = useState<string | null>();
  const [selectedRootDirImageUrl, setSelectedRootDirImageUrl] = useState<string | null>();

  if (articleList && articleDir) {
    for (const info of articleList) {
      // パスをdir,fileごとに分割
      const parts = info.path.split('/');
      // dirの名前だけ取得（英語）
      const dirNames = parts.slice(1, parts.length - 1);

      // パスがマスタに対して正しければ、dirを英語から日本語（表示名）に変換
      const displayDirPath = [];
      let currentDir = articleDir.children;
      if (currentDir) {
        for (let dirName of dirNames) {
          const dir: ArticleDir | null = currentDir?.find((x) => x.name === dirName) ?? null;
          if (!dir) break;
          displayDirPath.push(dir.display_name);
          currentDir = dir.children;
        }
      }

      // 表示名を格納
      info.displayDirPath = displayDirPath;

      // ディレクトリパス格納
      info.dirPath = parts.slice(0, parts.length - 1).join('/');

      // 日時変換
      info.created_at = new Date(info.created_at);
    }
  }

  const [flg, setFlg] = useState(true);
  if (flg && articleList) {
    articleList.sort((a, b) => {
      return a.created_at > b.created_at ? -1 : 1;
    });
    originalArticleInfo = [...articleList];
  }

  const refleshDirList = (selectedDir: ArticleDir) => {
    setFlg(false);

    // == 表示用DIRパス ==
    const digDir = (dir: ArticleDir) => {
      let dirPath: string = dir.display_name;
      if (dir.parentDir && dir.parentDir.name !== "root") {
        dirPath = digDir(dir.parentDir) + " > " + dirPath;
      }
      return dirPath;
    };

    if (selectedDir && selectedDir.isChecked) {
      if(selectedDir instanceof SpecialArticleDir){
        setSelectedRootDirImageUrl("/special_icon_black.svg");
      }
      else{
        setSelectedRootDirImageUrl("/analyze_icon_black.svg");
      }
      setSelectedDisplayDirPath(digDir(selectedDir));
    }
    else{
      setSelectedDisplayDirPath(null);
      setSelectedRootDirImageUrl(null);
    }
    // ==================

    const dig_dir_uncheck = (d?: ArticleDir) => {
      if (d) {

        if (d != selectedDir) {
          d.isDisplayChecked = false;
          d.isChecked = false;
        }
        if (d.children) {
          d.children.forEach((child) => {
            dig_dir_uncheck(child);
          });
        }
      }
    }

    const dig_dir_child_check = (d?: ArticleDir) => {
      if (d) {

        d.isChecked = selectedDir.isChecked;
        if (d.children) {
          d.children.forEach((child) => {
            dig_dir_child_check(child);
          });
        }
      }
    }

    const dig_dir_parent_check = (d?: ArticleDir) => {
      if (d) {

        d.isChecked = selectedDir.isChecked;
        if (d.parentDir && d.parentDir.name != 'root') {
          dig_dir_parent_check(d.parentDir);
        }
      }
    }

    if (selectedDir) {
      dig_dir_uncheck(articleDir);
      dig_dir_uncheck(specialArticleDir);

      dig_dir_child_check(selectedDir);
      dig_dir_parent_check(selectedDir);
    }

    if (articleDir && specialArticleDir) {
      // ディレクトリリスト更新
      setArticleDir(articleDir);

      // 特集ディレクトリリスト更新
      setSpecialArticleDir(specialArticleDir);

      // 記事リスト更新
      const dig_dir_path = (dir: ArticleDir) => {
        let paths: string[] = [];

        if (dir.children) {
          paths = paths.concat(...dir.children.map((child) => {
            return dig_dir_path(child);
          }));
          paths = paths.map((path) => "/" + dir.name + path);
        }

        const ownDirPath = dir.isChecked ? "/" + dir.name : "/";

        return [ownDirPath, ...paths];
      }

      // 特集記事リスト更新
      const dig_special_dir_path = (dir: SpecialArticleDir) => {
        let articles: string[] = [];

        if (dir.children) {
          articles = articles.concat(...dir.children.map((child) => {
            return dig_special_dir_path(child);
          }));
        }

        if (dir.isChecked && dir.articles) {
          articles = articles.concat(dir.articles);
        }

        return articles;
      }

      // ディレクトリ
      let pathList = dig_dir_path(articleDir).map((path) => path.substr(5));
      pathList = pathList.filter((x) => x !== '' && x.slice(-1) !== '/');

      // 特集記事
      const specialPathList = dig_special_dir_path(specialArticleDir);

      if (pathList.length === 0 && specialPathList.length === 0) {
        // 何もチェックされていない時は全部表示
        setArticleList([...originalArticleInfo].sort((a, b) => {
          return a.created_at > b.created_at ? -1 : 1;
        }));
      }
      else {
        setArticleList([...originalArticleInfo.filter((x) => {
          return pathList.includes(x.dirPath) || specialPathList.includes(x.path)
        }).sort((a, b) => {
          return a.created_at > b.created_at ? -1 : 1;
        })]);
      }
    }
  }

  return (
    <>
      <GlobalCss />

      <Routes>
        <Route path="/" element={<Top articleDir={articleDir} specialArticleDir={specialArticleDir} refleshDirList={refleshDirList} articleList={articleList} selectedDisplayDirPath={selectedDisplayDirPath} selectedRootDirImageUrl={selectedRootDirImageUrl}/>} />
        <Route path="/article/*" element={<Article articleList={articleList} selectedDisplayDirPath={selectedDisplayDirPath}/>} />
      </Routes>
    </>
  );
}

export default App;
