import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import { FaSquareXTwitter } from 'react-icons/fa6'

const HeaderContainer = styled.div`
    background-color:#434343ff;
    position: sticky;
    height: 80px;
    padding-left: 30px;
    box-sizing: border-box;
    display:flex;
    top:0;
    z-index: 999;
`;

const TitleContainer = styled.div`
height:80px;
cursor: pointer;
`

const TitleImage = styled.img`
height:100%;
`

const HeaderMessage = styled.h2`
    font-size: 12px;
    color: white;
    margin: auto 10px;
`;

const SnsContainer = styled.div`
margin: auto 30px auto auto;
`

export const Header = () => {
    const navigate = useNavigate();

    return (
        <HeaderContainer>
            <TitleContainer onClick={() => navigate("/")}>
                <TitleImage src="/title.png" alt="Data Science Wiki" onClick={() => navigate("/")} />
            </TitleContainer>

            <HeaderMessage>データサイエンティストによる、データサイエンティストのためのWebサイト</HeaderMessage>

            <SnsContainer>
                <a href="https://twitter.com/ds_wiki_?ref_src=twsrc%5Etfw" target="_blank">
                    <FaSquareXTwitter color={'#ccc'} size={40} />
                </a>
            </SnsContainer>
        </HeaderContainer>
    );
};