import { DirMasterEntity } from "./entity/dirMasterEntity";

export class ArticleDir {
    public name: string;
    public display_name: string;
    public children: ArticleDir[] | null;
    public isChecked: boolean;
    public isDisplayChecked: boolean;
    public isExpand: boolean;
    public parentDir: ArticleDir | null;

    constructor(name: string, display_name: string, parentDir: ArticleDir | null = null) {
        this.name = name;
        this.display_name = display_name;
        this.children = null;
        this.isChecked = false;
        this.isDisplayChecked = false;
        this.isExpand = false;
        this.parentDir = parentDir;
    }

    setChildren(entities: DirMasterEntity[] | null){
        if(entities){
            this.children = [];

            for(let entity of entities){
                const child: ArticleDir = new ArticleDir(entity.name, entity.display_name, this);
    
                child.setChildren(entity.children);

                this.children.push(child);
            }
        }
    }
}