import styled from "styled-components";

import { Header } from '../parts/header/Header';
import { HeaderSp } from '../parts/header/HeaderSp';
import { SideBar } from "./parts/sidebar/SideBar";
import { SideBarSp } from "./parts/sidebarSp/SideBarSp";
import { Main } from "./parts/main/Main";
import { MainSp } from "./parts/main/MainSp";
import { Footer } from '../parts/footer/Footer';

import { mediaQuery, useMediaQuery } from '../../hooks/mediaQueryProvider'
import { ArticleDir } from "../../models/articleDir";
import { SpecialArticleDir } from "../../models/specialArticleDir";
import { ArticleInfo } from "../../models/articleInfo";

const TopContainer = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #efefefff;
`;

const ContentContainer = styled.div`
display: flex;
flex-grow: 1;
`;

const ContentContainerSp = styled.div`

`;

type Props = {
    articleDir?: ArticleDir,
    specialArticleDir?: SpecialArticleDir,
    refleshDirList: (dir: ArticleDir) => void,
    articleList?: ArticleInfo[],
    selectedDisplayDirPath?: string | null,
    selectedRootDirImageUrl?: string | null
}

export const Top = (props: Props) => {
    const isSp = useMediaQuery(mediaQuery.sp);

    return (
        <TopContainer>
            {isSp ? (
                <HeaderSp />
            ) : (
                <Header />
            )}

            {isSp ? (
                <ContentContainerSp>
                    <SideBarSp articleDir={props.articleDir} specialArticleDir={props.specialArticleDir} refleshDirList={props.refleshDirList} count={props.articleList?.length} />
                    <MainSp articleList={props.articleList} selectedDisplayDirPath={props.selectedDisplayDirPath} selectedRootDirImageUrl={props.selectedRootDirImageUrl}/>
                </ContentContainerSp>
            ) : (
                <ContentContainer>
                    <SideBar articleDir={props.articleDir} specialArticleDir={props.specialArticleDir} refleshDirList={props.refleshDirList} />

                    <Main articleList={props.articleList} selectedDisplayDirPath={props.selectedDisplayDirPath} selectedRootDirImageUrl={props.selectedRootDirImageUrl}/>
                </ContentContainer>
            )}

            <Footer />
        </TopContainer>
    );
};