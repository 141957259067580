import axios from "axios";
import { DirMasterEntity } from "../models/entity/dirMasterEntity";
import { ArticleInfoEntity } from "../models/entity/articleInfoEntity";
import { SpecialDirMasterEntity } from "../models/entity/specialDirMasterEntity";

export const getDirMaster = async (fileEndpoint: string) : Promise<DirMasterEntity[]> =>{
    const response = await axios.get<DirMasterEntity[]>(`${fileEndpoint}/master/dir_master.json`)

    return response.data;
};

export const getArticleInfo = async (fileEndpoint: string) : Promise<ArticleInfoEntity[]> =>{
    const response = await axios.get<ArticleInfoEntity[]>(`${fileEndpoint}/article_info.json`);

    return response.data;
};

export const getSpecialDirMaster = async (fileEndpoint: string) : Promise<SpecialDirMasterEntity[]> =>{
    const response = await axios.get(`${fileEndpoint}/master/special_dir_master.json`);

    return response.data;
};

/* export const getSpecialArticleInfo = async (fileEndpoint: string) : Promise<string> =>{
    const response = await axios.get(`${fileEndpoint}/special_article_info.json`);

    return response.data;
}; */