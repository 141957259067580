import { ArticleDir } from "./articleDir";
import { SpecialDirMasterEntity } from "./entity/specialDirMasterEntity";

export class SpecialArticleDir extends ArticleDir{
    public children: SpecialArticleDir[] | null;
    public articles: string[] | null;

    constructor(name: string, display_name: string, parentDir: ArticleDir | null = null, articles: string[] | null = null) {
        super(name, display_name, parentDir);
        this.children = null;
        this.articles = articles;
    }

    setChildren(entities: SpecialDirMasterEntity[] | null){
        if(entities){
            this.children = [];

            for(let entity of entities){
                const child: SpecialArticleDir = new SpecialArticleDir(entity.name, entity.display_name, this, entity.articles);
    
                child.setChildren(entity.children);

                this.children.push(child);
            }
        }
    }
}