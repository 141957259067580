import styled from "styled-components";

import { CheckBoxMenu } from "./checkbox/CheckBoxMenu";

import "./humberger.css";
import { useState } from "react";
import { ArticleDir } from "../../../../models/articleDir";
import { SpecialArticleDir } from "../../../../models/specialArticleDir";
import { ArticleInfo } from "../../../../models/articleInfo";
import { GPTsMenu } from "./GPTsMenu";

const SideBarContainer = styled.div`
width: 50px;
box-sizing: border-box;
background-color: #666666ff;
color: #fff;
position: sticky;
top:10px;
right:10px;
margin: 0 0 0 auto;
z-index:999;
border-radius: 10px;
transition: .2s;
height: 50px;
opacity: 0.8;
margin-top: 10px;

&.is_active{
    position: fixed;
    height:100%;
    width: 100%;
    opacity:1;
    top:0;right:0;
    border-radius: 0;
    margin-top: 0;
}
`;

const SideBarContents = styled.div`
padding:0;
margin-top: 10px;
height:calc(100% - 40px);
position: relative;
overflow-y: auto;
&::-webkit-scrollbar{
    width: 5px;
}
&::-webkit-scrollbar-thumb{
    background-color: #888;
    border-radius: 5px;
}
&.is_active{
    animation: fadein 0.2s ease-in;
}

&.is_deactive{
    display: none;
}
`;

const ListCountContainer = styled.div`
position: sticky;
margin: 20px 0 0 30px;
font-size: 18px;
color: #e6d72a;
top:20px;
height:20px;

&.is_active{
    animation: fadein 0.2s ease-in;
}

&.is_deactive{
    display: none;
}
`;

type Props = {
    articleDir?: ArticleDir,
    specialArticleDir?: SpecialArticleDir,
    refleshDirList: (dir:ArticleDir) => void,
    articleList?: ArticleInfo[],
    count?: number
}

export const SideBarSp = (props: Props) => {

    let [isView, setIsView] = useState(false);

    const toggleIsView = () => {
        setIsView(prev => !prev);
    }

    return (
        <SideBarContainer className={isView ? "is_active" : "is_deactive"}>

            <ListCountContainer className={isView ? "is_active" : "is_deactive"}>
                検索結果: {props.count}件
            </ListCountContainer>

            <SideBarContents className={isView ? "is_active" : "is_deactive"}>
                
                <GPTsMenu/>

                <CheckBoxMenu existAll={false} dir={props.specialArticleDir} refleshDirList={props.refleshDirList} title={"特集記事"} dirKey="special" imageUrl="/special_icon.svg" />

                <CheckBoxMenu existAll={false} dir={props.articleDir} refleshDirList={props.refleshDirList} title={"分析手法"} count={props.count} dirKey="analyze" imageUrl="/analyze_icon.svg" />
            </SideBarContents>

            <button className={isView ? "hamburger is_active" : "hamburger"} onClick={toggleIsView}>
                <div className={isView ? "button_contents is_active" : "button_contents"}>
                    <div>
                        <span className={isView ? "hamburger_bar is_active" : "hamburger_bar"}></span>
                        <span className={isView ? "hamburger_bar is_active" : "hamburger_bar"}></span>
                        <span className={isView ? "hamburger_bar is_active" : "hamburger_bar"}></span>
                    </div>
                </div>
            </button>
        </SideBarContainer>
    );
};